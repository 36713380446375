<template>
    <ul>
        <li :class="item.Class" @click="mainAction(item)" v-for="(item, index) in data" :key="index">
            <b-icon :icon="item.Icon" size="is-small" class="mr-2" v-if="item.Icon"></b-icon>
            <span>{{ item.Name }}</span>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        data: {
            type: Array
        }
    }
}
</script>
